





import { Vue, Component, Mixins } from 'vue-property-decorator'

@Component
export default class extends Mixins() {}
